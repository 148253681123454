import { ReactNode } from 'react';
import {
  Box,
  CloseButton,
  Flex,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Image,
  Stack,
  Spacer,
  IconButton,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import Icon from '@components/Icon';
import Footer from '@components/Footer';
import { AppHeaderActions } from '@components/AppHeaderActions';
import LinkWithQuery from '@components/LinkWithQuery';
import { useChainId } from '@hooks/useChainId';
import { LinkItem } from '@components/LinkItems';

const SIDEBAR_WIDTH = 64;

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

interface NavItemProps extends FlexProps {
  icon: LinkItem['icon'];
  isActive: boolean | undefined;
  href: LinkItem['href'];
}

function NavItem({ icon, children, isActive, href, ...rest }: NavItemProps) {
  return (
    <LinkWithQuery href={href}>
      <Flex
        align="center"
        p="4"
        pl={8}
        borderLeftWidth={2}
        borderColor={isActive ? 'brand.200' : 'transparent'}
        role="group"
        cursor="pointer"
        color={isActive ? 'brand.200' : 'blue.200'}
        _hover={{
          bg: 'navy',
          boxShadow: 'inset -4px 0 5px -4px rgba(18, 18, 19, 0.6)',
          color: isActive ? 'brand.200' : 'white',
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="5"
            width="16px"
            height="16px"
            stroke={isActive ? 'brand.200' : 'blue.200'}
            _groupHover={{
              stroke: isActive ? 'brand.200' : 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </LinkWithQuery>
  );
}

const sidebarLogoUrls = {
  'osmosis-1': '/images/osmoMascot.svg',
  'osmo-test-5': '/images/osmoMascot.svg',
  'kaiyo-1': '/images/kujiMascot.svg',
  'harpoon-4': '/images/kujiMascot.svg',
  'archway-1': 'images/archwaySidebarLogo.svg',
  'constantine-3': 'images/archwaySidebarLogo.svg',
  'neutron-1': 'images/neutronMascot.svg',
  'pion-1': 'images/neutronMascot.svg',
};

function SidebarContent({ onClose, linkItems, ...rest }: SidebarProps & { linkItems: LinkItem[] }) {
  const router = useRouter();
  const { chainId } = useChainId();

  return (
    <Flex
      bg={useColorModeValue('white', 'abyss.200')}
      w={{ base: 'full', md: SIDEBAR_WIDTH }}
      pos="fixed"
      h="full"
      boxShadow="inset -4px 0 5px -4px rgba(18, 18, 19, 0.6)"
      bgImage={sidebarLogoUrls[chainId]}
      bgPosition="bottom"
      bgSize="contain"
      bgRepeat="no-repeat"
      {...rest}
    >
      <Flex h="16" alignItems="center" mx="8" justifyContent="space-between">
        <LinkWithQuery href="/">
          <Image
            cursor="pointer"
            src={
              {
                'osmosis-1': '/images/osmoLogo.svg',
                'osmo-test-5': '/images/osmoLogo.svg',
                'kaiyo-1': '/images/logo.svg',
                'harpoon-4': '/images/logo.svg',
                'archway-1': '/images/logo.svg',
                'constantine-3': '/images/logo.svg',
                'neutron-1': '/images/logo.svg',
                'pion-1': '/images/logo.svg',
              }[chainId]
            }
            w={105}
          />
        </LinkWithQuery>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      <Box backdropFilter="auto" backdropBlur="3px">
        {linkItems
          .filter((link) => !link.exclude?.includes(chainId))
          .map((link) => (
            <NavItem href={link.href} isActive={link.href === router.route} key={link.name} icon={link.icon}>
              {link.name}
              {link.child}
            </NavItem>
          ))}
      </Box>
      <Stack
        position="absolute"
        p={6}
        bottom={0}
        color="grey.200"
        w="full"
        spacing={2}
        backdropFilter="auto"
        backdropBlur="3px"
      >
        <Footer />
      </Stack>
    </Flex>
  );
}

interface MobileProps extends FlexProps {
  onOpen: () => void;
}

function MobileNav({ onOpen, linkItems, ...rest }: MobileProps & { linkItems: LinkItem[] }) {
  const router = useRouter();
  const { chainId: chain } = useChainId();
  return (
    <Flex
      px={8}
      py={4}
      alignItems="center"
      bg={useColorModeValue('white', 'navy')}
      justifyContent="flex-start"
      flexDirection="column"
      {...rest}
    >
      <Flex w="full" pb={8} alignItems="center">
        <Text fontSize="2xl" fontWeight="bold">
          <Image
            src={
              {
                'osmosis-1': '/images/osmoLogo.svg',
                'osmo-test-5': '/images/osmoLogo.svg',
                'kaiyo-1': '/images/logo.svg',
                'harpoon-4': '/images/logo.svg',
                'archway-1': '/images/logo.svg',
                'constantine-3': '/images/logo.svg',
                'neutron-1': '/images/logo.svg',
                'pion-1': '/images/logo.svg',
              }[chain]
            }
            w={105}
          />
        </Text>
        <Spacer />
        <AppHeaderActions />
      </Flex>
      <Flex w="full" justifyContent="space-between">
        {linkItems
          .filter((link) => !link.exclude?.includes(chain))
          .map((link) => (
            <LinkWithQuery href={link.href} key={link.name}>
              <IconButton
                aria-label={link.name}
                variant="link"
                width={12}
                height={12}
                p={0}
                borderBottomWidth={2}
                borderColor={link.href === router.asPath ? 'brand.200' : 'transparent'}
                borderRadius="none"
                _hover={{
                  bg: 'darkGrey',
                  stroke: link.href === router.asPath ? 'brand.200' : 'white',
                }}
                icon={
                  <Icon
                    as={link.icon}
                    cursor="pointer"
                    color={link.href === router.asPath ? 'brand.200' : '#D5F8F9'}
                    stroke={link.href === router.asPath ? 'brand.200' : '#D5F8F9'}
                  />
                }
              />
            </LinkWithQuery>
          ))}
      </Flex>
    </Flex>
  );
}

export default function Sidebar({ children, linkItems }: { children: ReactNode; linkItems: LinkItem[] }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'navy')}>
      <SidebarContent linkItems={linkItems} onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent linkItems={linkItems} onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <MobileNav linkItems={linkItems} display={{ base: 'flex', md: 'none' }} onOpen={onOpen} />
      <Box ml={{ base: 0, md: SIDEBAR_WIDTH }}>{children}</Box>
    </Box>
  );
}
