enum StrategyUrls {
  DCAIn = '/create-strategy/dca-in/assets',
  DCAOut = '/create-strategy/dca-out/assets',
  DCAPlusIn = '/create-strategy/dca-plus-in/assets',
  DCAPlusOut = '/create-strategy/dca-plus-out/assets',
  WeightedScaleIn = '/create-strategy/weighted-scale-in/assets',
  WeightedScaleOut = '/create-strategy/weighted-scale-out/assets',
  StreamingSwap = '/create-strategy/streaming-swap/assets',
}

export default StrategyUrls;
