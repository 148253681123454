export type DenomInfo = {
  chain: string;
  id: string;
  name: string;
  icon: string;
  stakeable: boolean;
  stable: boolean;
  stakeableAndSupported: boolean;
  enabled: boolean;
  minimumSwapAmount: number;
  significantFigures: number;
  enabledInDcaPlus: boolean;
  pricePrecision: number;
  isCw20?: boolean;
  coingeckoId?: string | undefined;
};

export type InitialDenomInfo = DenomInfo & {
  coingeckoId: string;
};

export type ResultingDenomInfo = DenomInfo;

export const fromPartial = (denomInfo: Partial<InitialDenomInfo>): InitialDenomInfo => {
  if (!denomInfo.chain || !denomInfo.id) {
    throw new Error(`Invalid partial denom info: ${JSON.stringify(denomInfo)}`);
  }

  return {
    chain: denomInfo.chain,
    id: denomInfo.id,
    name: denomInfo.id,
    icon: '/images/circleDollar.svg',
    minimumSwapAmount: 0,
    stakeable: false,
    stable: false,
    coingeckoId: '',
    stakeableAndSupported: false,
    enabled: true,
    significantFigures: 6,
    pricePrecision: 3,
    enabledInDcaPlus: false,
    isCw20: false,
    ...denomInfo,
  };
};
