const denoms = {
  ok: true,
  data: [
    {
      isNative: true,
      isDerivative: false,
      isPriceWatch: true,
      isHidden: false,
      id: 'aarch',
      createdAt: '2023-07-03T17:50:09.622Z',
      decimals: 18,
      denom: 'aarch',
      isSourceDenom: true,
      label: 'ARCH',
      symbol: 'ARCH',
      updatedAt: '2024-03-04T04:10:08.379Z',
      xDerivativeTarget: 'archway1cutfh7m87cyq5qgqqw49f289qha7vhsg6wtr6rl5fvm28ulnl9ssg0vk0n',
      price: 0.1938216307401719,
      price_24h_change: -4.461360404079951,
      standardPoolPriceSources: [
        {
          address: 'archway1tadvtdm4ah6vnt8tzhfmk0e3aj62wxsvrq43zxlerqspufs2ydjs88pspp',
          offerAssetDenom: 'archway1m273xq2fjmn993jm4kft5c49w2c70yfv5zypt3d92cqp4n5faefqqkuf0l',
        },
        {
          address: 'archway139hgd4rm3xyuqyrn63ardjxkg7puzafne7u3pj04qag7ld9cyhnqk9540y',
          offerAssetDenom: 'archway1yjdgfut7jkq5xwzyp6p5hs7hdkmszn34zkhun6mglu3falq3yh8sdkaj7j',
        },
        {
          address: 'archway1evz8agrnppzq7gt2nnutkmqgpm86374xds0alc7hru987f9v4hqsejqfaq',
          offerAssetDenom: 'ibc/B9E4FD154C92D3A23BEA029906C4C5FF2FE74CB7E3A058290B77197A263CF88B',
        },
        {
          address: 'archway17wenhyfn5km24884s0f2xuj6g7mccwvhaptqna7lg2ufy5pv88usk3t5cq',
          offerAssetDenom: 'ibc/13C5990F84FA5D472E1F8BB1BAAEA8774DA5F24128EC02B119107AD21FB52A61',
        },
        {
          address: 'archway18wdt3zsqgk2rgvgkqga44z5hz4p2yjsuszxpgeew8ftv4nev99esar8wt2',
          offerAssetDenom: 'ibc/C0336ECF2DF64E7D2C98B1422EC2B38DE9EF33C34AAADF18C6F2E3FFC7BE3615',
        },
        {
          address: 'archway1yr9nad5dv34nze2wtx6ye5hl38ymy5q70t4eu96l8hydwhs9tnkq3gwmev',
          offerAssetDenom: 'archway1yv8uhe795xs4fwz6mjm278yr35ps0yagjchfp39q5x49dty9jgssm5tnkv',
        },
      ],
    },
    {
      isNative: false,
      isDerivative: true,
      isPriceWatch: false,
      isHidden: false,
      label: 'xARCH',
      address: 'archway1cutfh7m87cyq5qgqqw49f289qha7vhsg6wtr6rl5fvm28ulnl9ssg0vk0n',
      createdAt: '2023-07-03T17:50:18.991Z',
      decimals: 18,
      derivativeContract: 'archway15907pq6kzpqvrgxsp2l695l9t2aepdtyu9nr0xezlj4ntwtfz7gs2gdx69',
      derivativeSource: 'aarch',
      equalToOtherIDPriceSource: 'aarch',
      id: 'archway1cutfh7m87cyq5qgqqw49f289qha7vhsg6wtr6rl5fvm28ulnl9ssg0vk0n',
      symbol: 'xARCH',
      totalSupply: '12834322303104968739327059',
      updatedAt: '2024-03-04T04:10:07.209Z',
      price: 0.1938216307401719,
      price_24h_change: -4.461360404079951,
      standardPoolPriceSources: [],
    },
    {
      isNative: true,
      isDerivative: false,
      isPriceWatch: true,
      isHidden: false,
      label: 'ATOM',
      createdAt: '2023-07-03T20:17:00.672Z',
      decimals: 6,
      denom: 'ibc/27394FB092D2ECCD56123C74F36E4C1F926001CEADA9CA97EA622B25F41E5EB2',
      denom_trace: { path: 'transfer/channel-0', base_denom: 'uatom' },
      geckoIDPriceSource: 'cosmos',
      id: 'ibc/27394FB092D2ECCD56123C74F36E4C1F926001CEADA9CA97EA622B25F41E5EB2',
      symbol: 'ATOM',
      updatedAt: '2024-03-04T04:10:08.379Z',
      xDerivativeTarget: 'archway1m273xq2fjmn993jm4kft5c49w2c70yfv5zypt3d92cqp4n5faefqqkuf0l',
      price: 12.39,
      price_24h_change: 1.7305499957316122,
      standardPoolPriceSources: [],
    },
    {
      isNative: false,
      isDerivative: true,
      isPriceWatch: false,
      isHidden: false,
      label: 'xATOM',
      address: 'archway1m273xq2fjmn993jm4kft5c49w2c70yfv5zypt3d92cqp4n5faefqqkuf0l',
      createdAt: '2023-07-03T20:17:07.729Z',
      decimals: 6,
      derivativeContract: 'archway1k08ndhyna7krqaex9ejlkxczhf3d6fu7j0cdnh7ajdw3rhwmqutqgescl0',
      derivativeSource: 'ibc/27394FB092D2ECCD56123C74F36E4C1F926001CEADA9CA97EA622B25F41E5EB2',
      equalToOtherIDPriceSource: 'ibc/27394FB092D2ECCD56123C74F36E4C1F926001CEADA9CA97EA622B25F41E5EB2',
      id: 'archway1m273xq2fjmn993jm4kft5c49w2c70yfv5zypt3d92cqp4n5faefqqkuf0l',
      symbol: 'xATOM',
      totalSupply: '28851266345',
      updatedAt: '2024-03-04T04:10:07.270Z',
      price: 12.39,
      price_24h_change: 1.7305499957316122,
      standardPoolPriceSources: [],
    },
    {
      isNative: true,
      isDerivative: false,
      isPriceWatch: true,
      isHidden: false,
      label: 'USDC.axl',
      createdAt: '2023-07-11T12:03:04.659Z',
      decimals: 6,
      denom: 'ibc/B9E4FD154C92D3A23BEA029906C4C5FF2FE74CB7E3A058290B77197A263CF88B',
      denom_trace: { path: 'transfer/channel-13', base_denom: 'uusdc' },
      geckoIDPriceSource: 'axlusdc',
      id: 'ibc/B9E4FD154C92D3A23BEA029906C4C5FF2FE74CB7E3A058290B77197A263CF88B',
      symbol: 'USDC.axl',
      updatedAt: '2024-03-04T04:10:08.379Z',
      price: 0.999347,
      price_24h_change: -0.12518045418582338,
      standardPoolPriceSources: [],
    },
    {
      isNative: true,
      isDerivative: false,
      isPriceWatch: true,
      isHidden: false,
      label: 'ETH.axl',
      createdAt: '2023-07-11T13:02:51.569Z',
      decimals: 18,
      denom: 'ibc/13C5990F84FA5D472E1F8BB1BAAEA8774DA5F24128EC02B119107AD21FB52A61',
      denom_trace: { path: 'transfer/channel-13', base_denom: 'weth-wei' },
      geckoIDPriceSource: 'axlweth',
      id: 'ibc/13C5990F84FA5D472E1F8BB1BAAEA8774DA5F24128EC02B119107AD21FB52A61',
      symbol: 'ETH.axl',
      updatedAt: '2024-03-04T04:10:08.379Z',
      price: 3463.46,
      price_24h_change: 0.9551995640419073,
      standardPoolPriceSources: [],
    },
    {
      isNative: true,
      isDerivative: false,
      isPriceWatch: true,
      isHidden: false,
      label: 'JKL',
      createdAt: '2023-07-18T18:14:39.954Z',
      decimals: 6,
      denom: 'ibc/926432AE1C5FA4F857B36D970BE7774C7472079506820B857B75C5DE041DD7A3',
      denom_trace: { path: 'transfer/channel-14', base_denom: 'ujkl' },
      geckoIDPriceSource: 'jackal-protocol',
      id: 'ibc/926432AE1C5FA4F857B36D970BE7774C7472079506820B857B75C5DE041DD7A3',
      symbol: 'JKL',
      updatedAt: '2024-03-04T04:10:08.379Z',
      xDerivativeTarget: 'archway1yjdgfut7jkq5xwzyp6p5hs7hdkmszn34zkhun6mglu3falq3yh8sdkaj7j',
      price: 0.772681,
      price_24h_change: -10.559405304384619,
      standardPoolPriceSources: [],
    },
    {
      isNative: false,
      isDerivative: true,
      isPriceWatch: false,
      isHidden: false,
      label: 'xJKL',
      address: 'archway1yjdgfut7jkq5xwzyp6p5hs7hdkmszn34zkhun6mglu3falq3yh8sdkaj7j',
      createdAt: '2023-07-18T18:14:44.552Z',
      decimals: 6,
      derivativeContract: 'archway12vdwz870t998k9w337d8jwjq7cm94dzx33hm42u7dgjn6yryvrfqd7qf50',
      derivativeSource: 'ibc/926432AE1C5FA4F857B36D970BE7774C7472079506820B857B75C5DE041DD7A3',
      equalToOtherIDPriceSource: 'ibc/926432AE1C5FA4F857B36D970BE7774C7472079506820B857B75C5DE041DD7A3',
      id: 'archway1yjdgfut7jkq5xwzyp6p5hs7hdkmszn34zkhun6mglu3falq3yh8sdkaj7j',
      symbol: 'xJKL',
      totalSupply: '1859533178032',
      updatedAt: '2024-03-04T04:10:07.333Z',
      price: 0.772681,
      price_24h_change: -10.559405304384619,
      standardPoolPriceSources: [],
    },
    {
      isNative: false,
      isDerivative: false,
      isPriceWatch: true,
      isHidden: false,
      label: 'sARCH',
      address: 'archway1t2llqsvwwunf98v692nqd5juudcmmlu3zk55utx7xtfvznel030saclvq6',
      createdAt: '2023-08-09T12:54:21.300Z',
      decimals: 18,
      id: 'archway1t2llqsvwwunf98v692nqd5juudcmmlu3zk55utx7xtfvznel030saclvq6',
      lsdContractPriceSource: {
        contractWithRatio: 'archway1p3wrpsrxldc6laecc8r3ck52cmg3chu06mmm3thqvt6exy36f79q575rsq',
        ratioQueryResponsePath: 'ratio',
        baseAssetID: 'aarch',
      },
      symbol: 'sARCH',
      updatedAt: '2024-03-04T04:10:06.469Z',
      price: 0.21263483378673312,
      totalSupply: '1607989956341301294356520',
      price_24h_change: -4.461360404079951,
      standardPoolPriceSources: [],
    },
    {
      isNative: true,
      isDerivative: false,
      isPriceWatch: true,
      isHidden: false,
      label: 'BLD',
      createdAt: '2023-09-27T21:55:18.618Z',
      decimals: 6,
      denom: 'ibc/8CB56C813A5C2387140BBEAABCCE797AFA0960C8D07B171F71A5188726CFED2C',
      denom_trace: { path: 'transfer/channel-20', base_denom: 'ubld' },
      geckoIDPriceSource: 'agoric',
      id: 'ibc/8CB56C813A5C2387140BBEAABCCE797AFA0960C8D07B171F71A5188726CFED2C',
      staticPriceSource: null,
      symbol: 'BLD',
      updatedAt: '2024-03-04T04:10:08.379Z',
      price: 0.186593,
      price_24h_change: 1.7306836410293502,
      xDerivativeTarget: 'archway1yv8uhe795xs4fwz6mjm278yr35ps0yagjchfp39q5x49dty9jgssm5tnkv',
      standardPoolPriceSources: [],
    },
    {
      isNative: true,
      isDerivative: false,
      isPriceWatch: true,
      isHidden: false,
      label: 'IST',
      createdAt: '2023-09-27T21:55:18.898Z',
      decimals: 6,
      denom: 'ibc/C0336ECF2DF64E7D2C98B1422EC2B38DE9EF33C34AAADF18C6F2E3FFC7BE3615',
      denom_trace: { path: 'transfer/channel-20', base_denom: 'uist' },
      geckoIDPriceSource: 'inter-stable-token',
      id: 'ibc/C0336ECF2DF64E7D2C98B1422EC2B38DE9EF33C34AAADF18C6F2E3FFC7BE3615',
      staticPriceSource: null,
      symbol: 'IST',
      updatedAt: '2024-03-04T04:10:08.379Z',
      price: 0.995703,
      price_24h_change: -0.5970593895789236,
      standardPoolPriceSources: [],
    },
    {
      isNative: true,
      isDerivative: false,
      isPriceWatch: true,
      isHidden: false,
      label: 'USDC.nobl',
      createdAt: '2023-10-02T14:49:29.557Z',
      decimals: 6,
      denom: 'ibc/43897B9739BD63E3A08A88191999C632E052724AB96BD4C74AE31375C991F48D',
      denom_trace: { path: 'transfer/channel-29', base_denom: 'uusdc' },
      geckoIDPriceSource: 'usd-coin',
      id: 'ibc/43897B9739BD63E3A08A88191999C632E052724AB96BD4C74AE31375C991F48D',
      staticPriceSource: null,
      symbol: 'USDC.nobl',
      updatedAt: '2024-03-04T04:10:08.379Z',
      price: 0.999544,
      price_24h_change: -0.09808128858574224,
      standardPoolPriceSources: [],
    },
    {
      isNative: false,
      isDerivative: false,
      isPriceWatch: true,
      isHidden: false,
      label: 'ampARCH',
      address: 'archway1fwurjg7ah4v7hhs6xsc3wutqpvmahrfhns285s0lt34tgfdhplxq6m8xg5',
      createdAt: '2023-10-02T17:50:39.139Z',
      decimals: 18,
      id: 'archway1fwurjg7ah4v7hhs6xsc3wutqpvmahrfhns285s0lt34tgfdhplxq6m8xg5',
      lsdContractPriceSource: {
        contractWithRatio: 'archway1yg4eq68xyll74tdrrcxkr4qpam4j9grknunmp74zzc6km988dadqy0utmj',
        ratioQueryResponsePath: 'exchange_rate',
        baseAssetID: 'aarch',
      },
      symbol: 'ampARCH',
      updatedAt: '2024-03-04T04:10:07.147Z',
      price: 0.21475107318696135,
      totalSupply: '391989159379102805484018',
      price_24h_change: -4.461360404079951,
      standardPoolPriceSources: [],
    },
    {
      isNative: true,
      isDerivative: false,
      isPriceWatch: true,
      isHidden: false,
      label: 'DEC',
      createdAt: '2023-10-10T17:14:00.249Z',
      decimals: 6,
      denom: 'ibc/E3409E92F78AE5BF44DBC7C4741901E21EF73B7B8F98C4D48F2BD360AF242C00',
      denom_trace: { path: 'transfer/channel-36', base_denom: 'udec' },
      geckoIDPriceSource: 'decentr',
      id: 'ibc/E3409E92F78AE5BF44DBC7C4741901E21EF73B7B8F98C4D48F2BD360AF242C00',
      staticPriceSource: null,
      symbol: 'DEC',
      updatedAt: '2024-03-04T04:10:08.379Z',
      price: 0.01422361,
      price_24h_change: -8.142657576056358,
      xDerivativeTarget: 'archway1veyq07az0d7mlp49sa9f9ef56w0dd240vjsy76yv0m4pl5a2x2uq698cs7',
      standardPoolPriceSources: [],
    },
    {
      isNative: false,
      isDerivative: true,
      isPriceWatch: false,
      isHidden: false,
      label: 'xDEC',
      address: 'archway1veyq07az0d7mlp49sa9f9ef56w0dd240vjsy76yv0m4pl5a2x2uq698cs7',
      createdAt: '2023-10-12T15:42:50.500Z',
      decimals: 6,
      derivativeContract: 'archway1y0q094yj9pzftqguqsl6dzpdu3d90xhdk78klak70yepg3w2sursargdhz',
      derivativeSource: 'ibc/E3409E92F78AE5BF44DBC7C4741901E21EF73B7B8F98C4D48F2BD360AF242C00',
      equalToOtherIDPriceSource: 'ibc/E3409E92F78AE5BF44DBC7C4741901E21EF73B7B8F98C4D48F2BD360AF242C00',
      id: 'archway1veyq07az0d7mlp49sa9f9ef56w0dd240vjsy76yv0m4pl5a2x2uq698cs7',
      symbol: 'xDEC',
      totalSupply: '6871038658647',
      updatedAt: '2024-03-04T04:10:07.395Z',
      price: 0.01422361,
      price_24h_change: -8.142657576056358,
      standardPoolPriceSources: [],
    },
    {
      isNative: true,
      isDerivative: false,
      isPriceWatch: true,
      isHidden: false,
      label: 'BCNA',
      createdAt: '2023-10-23T16:40:02.776Z',
      decimals: 6,
      denom: 'ibc/FCB240D2C3838369155A0FA8970A8BE3EC1042F698269B9D6D9859274F00A0BB',
      denom_trace: { path: 'transfer/channel-35', base_denom: 'ubcna' },
      geckoIDPriceSource: 'bitcanna',
      id: 'ibc/FCB240D2C3838369155A0FA8970A8BE3EC1042F698269B9D6D9859274F00A0BB',
      staticPriceSource: null,
      symbol: 'BCNA',
      updatedAt: '2024-03-04T04:10:08.379Z',
      price: 0.01528408,
      price_24h_change: 0.3340426678704427,
      xDerivativeTarget: 'archway1mast3w89sn8gtmvdhr6gfemu4jdx60563fajs5ucsc7s7e9jv00q7qw0hu',
      standardPoolPriceSources: [],
    },
    {
      isNative: true,
      isDerivative: false,
      isPriceWatch: true,
      isHidden: false,
      label: 'WBTC.axl',
      createdAt: '2023-10-25T17:09:32.746Z',
      decimals: 8,
      denom: 'ibc/3A2DEEBCD51D0B74FE7CE058D40B0BF4C0E556CE9219E8F25F92CF288FF35F56',
      denom_trace: { path: 'transfer/channel-13', base_denom: 'wbtc-satoshi' },
      geckoIDPriceSource: 'wrapped-bitcoin',
      id: 'ibc/3A2DEEBCD51D0B74FE7CE058D40B0BF4C0E556CE9219E8F25F92CF288FF35F56',
      symbol: 'WBTC.axl',
      updatedAt: '2024-03-04T04:10:08.379Z',
      price: 63474,
      price_24h_change: 2.186320238529636,
      standardPoolPriceSources: [],
    },
    {
      isNative: true,
      isDerivative: false,
      isPriceWatch: true,
      isHidden: false,
      label: 'ALTER',
      createdAt: '2023-10-27T17:59:32.664Z',
      decimals: 6,
      denom: 'ibc/E070901F36B129933202BEB3EB40A78BE242D8ECBA2D1AF9161DF06F35783900',
      denom_trace: {
        cw20_ics20: {
          ibcRecvChannel: 'channel-90',
          ibcSendChannel: 'channel-39',
          cw20Address: 'secret17ljp7wwesff85ewt8xlauxjt7zrlr2hh27wgvr',
          ics20Address: 'secret1tqmms5awftpuhalcv5h5mg76fa0tkdz4jv9ex4',
          chainId: 'secret-4',
          ics20CodeHash: 'f85b413b547b9460162958bafd51113ac266dac96a84c33b9150f68f045f2641',
        },
        path: 'transfer/channel-39',
        base_denom: 'cw20:secret17ljp7wwesff85ewt8xlauxjt7zrlr2hh27wgvr',
      },
      geckoIDPriceSource: 'alter',
      id: 'ibc/E070901F36B129933202BEB3EB40A78BE242D8ECBA2D1AF9161DF06F35783900',
      staticPriceSource: null,
      symbol: 'ALTER',
      updatedAt: '2024-03-04T04:10:08.379Z',
      price: 0.02656401,
      price_24h_change: 0.011100362778507693,
      standardPoolPriceSources: [],
    },
    {
      isNative: false,
      isDerivative: true,
      isPriceWatch: false,
      isHidden: false,
      label: 'xBCNA',
      address: 'archway1mast3w89sn8gtmvdhr6gfemu4jdx60563fajs5ucsc7s7e9jv00q7qw0hu',
      createdAt: '2023-11-07T08:56:48.491Z',
      decimals: 6,
      derivativeContract: 'archway1vmcl9d4vtcg4y9c33vm6xsph37fh4ncredu9r8hnekcle0dyt86sm04hvd',
      derivativeSource: 'ibc/FCB240D2C3838369155A0FA8970A8BE3EC1042F698269B9D6D9859274F00A0BB',
      equalToOtherIDPriceSource: 'ibc/FCB240D2C3838369155A0FA8970A8BE3EC1042F698269B9D6D9859274F00A0BB',
      id: 'archway1mast3w89sn8gtmvdhr6gfemu4jdx60563fajs5ucsc7s7e9jv00q7qw0hu',
      symbol: 'xBCNA',
      totalSupply: '922183222671',
      updatedAt: '2024-03-04T04:10:07.457Z',
      price: 0.01528408,
      price_24h_change: 0.3340426678704427,
      standardPoolPriceSources: [],
    },
    {
      isNative: true,
      isDerivative: false,
      isPriceWatch: true,
      isHidden: false,
      label: 'FLIX',
      createdAt: '2023-11-20T11:21:44.938Z',
      decimals: 6,
      denom: 'ibc/8E6E7AB89246F87DA936F0EEA0A40654E7FB6B0C3E834F447EB444AAD95A106F',
      denom_trace: { path: 'transfer/channel-37', base_denom: 'uflix' },
      geckoIDPriceSource: 'omniflix-network',
      id: 'ibc/8E6E7AB89246F87DA936F0EEA0A40654E7FB6B0C3E834F447EB444AAD95A106F',
      staticPriceSource: null,
      symbol: 'FLIX',
      updatedAt: '2024-03-04T04:10:08.379Z',
      price: 0.189769,
      price_24h_change: -3.572547329196296,
      xDerivativeTarget: 'archway1asgu5g79cdjcdd40lgefplszehykpwzcunx30ca4456a4tddmwcsrmtvx8',
      standardPoolPriceSources: [],
    },
    {
      isNative: false,
      isDerivative: true,
      isPriceWatch: false,
      isHidden: false,
      label: 'xFLIX',
      address: 'archway1asgu5g79cdjcdd40lgefplszehykpwzcunx30ca4456a4tddmwcsrmtvx8',
      createdAt: '2023-11-20T11:27:22.298Z',
      decimals: 6,
      derivativeContract: 'archway1ynk24y6kgdw457tkw8xe5klpdxvxc9lth0af0dez4c635a0nk6cqr9uy87',
      derivativeSource: 'ibc/8E6E7AB89246F87DA936F0EEA0A40654E7FB6B0C3E834F447EB444AAD95A106F',
      equalToOtherIDPriceSource: 'ibc/8E6E7AB89246F87DA936F0EEA0A40654E7FB6B0C3E834F447EB444AAD95A106F',
      id: 'archway1asgu5g79cdjcdd40lgefplszehykpwzcunx30ca4456a4tddmwcsrmtvx8',
      symbol: 'xFLIX',
      totalSupply: '353085789714',
      updatedAt: '2024-03-04T04:10:07.533Z',
      price: 0.189769,
      price_24h_change: -3.572547329196296,
      standardPoolPriceSources: [],
    },
    {
      isNative: true,
      isDerivative: false,
      isPriceWatch: true,
      isHidden: false,
      label: 'CMDX',
      createdAt: '2023-11-22T17:05:35.920Z',
      decimals: 6,
      denom: 'ibc/BF8BDCAA292B56035E669D80711D9881CC96796AC6BCB0376836FAD045355E37',
      denom_trace: { path: 'transfer/channel-55', base_denom: 'ucmdx' },
      geckoIDPriceSource: 'comdex',
      id: 'ibc/BF8BDCAA292B56035E669D80711D9881CC96796AC6BCB0376836FAD045355E37',
      staticPriceSource: null,
      symbol: 'CMDX',
      updatedAt: '2024-03-04T04:10:08.379Z',
      price: 0.04868293,
      price_24h_change: 0.6995209405070695,
      xDerivativeTarget: 'archway14fzmzu2apcdzwx9dspyk0yjmxwwnpmxh7zlezh5ywe4l0pky39ysgzd9gg',
      standardPoolPriceSources: [],
    },
    {
      isNative: false,
      isDerivative: true,
      isPriceWatch: false,
      isHidden: false,
      label: 'xCMDX',
      address: 'archway14fzmzu2apcdzwx9dspyk0yjmxwwnpmxh7zlezh5ywe4l0pky39ysgzd9gg',
      createdAt: '2023-11-22T17:05:43.950Z',
      decimals: 6,
      derivativeContract: 'archway15nw0fp2zkxue4xph2hu34yl9rxh37clv8uk6eum0uhz0hde0qawqfvejlz',
      derivativeSource: 'ibc/BF8BDCAA292B56035E669D80711D9881CC96796AC6BCB0376836FAD045355E37',
      equalToOtherIDPriceSource: 'ibc/BF8BDCAA292B56035E669D80711D9881CC96796AC6BCB0376836FAD045355E37',
      id: 'archway14fzmzu2apcdzwx9dspyk0yjmxwwnpmxh7zlezh5ywe4l0pky39ysgzd9gg',
      symbol: 'xCMDX',
      totalSupply: '223702138375',
      updatedAt: '2024-03-04T04:10:07.610Z',
      price: 0.04868293,
      price_24h_change: 0.6995209405070695,
      standardPoolPriceSources: [],
    },
    {
      isNative: true,
      isDerivative: false,
      isPriceWatch: true,
      isHidden: false,
      label: 'GRAV',
      createdAt: '2023-11-23T17:32:18.514Z',
      decimals: 6,
      denom: 'ibc/31D711D31CD5D83D98E76B1486EEDA1A38CD1F7D6FCBD03521FE51323115AECA',
      denom_trace: { path: 'transfer/channel-38', base_denom: 'ugraviton' },
      geckoIDPriceSource: 'graviton',
      id: 'ibc/31D711D31CD5D83D98E76B1486EEDA1A38CD1F7D6FCBD03521FE51323115AECA',
      staticPriceSource: null,
      symbol: 'GRAV',
      updatedAt: '2024-03-04T04:10:08.379Z',
      xDerivativeTarget: 'archway1zfnzv39cp4dv3jjy0aptn5msc02tjmy602l46u90dt729q80939qjgqcdj',
      price: 0.00334654,
      price_24h_change: -9.048646617619642,
      standardPoolPriceSources: [],
    },
    {
      isNative: false,
      isDerivative: true,
      isPriceWatch: false,
      isHidden: false,
      label: 'xGRAV',
      address: 'archway1zfnzv39cp4dv3jjy0aptn5msc02tjmy602l46u90dt729q80939qjgqcdj',
      createdAt: '2023-11-23T17:32:29.913Z',
      decimals: 6,
      derivativeContract: 'archway1rlznwt9s5xuwtamg4v9ldu5qzwkqkuaf3xvlv2ueu5700xhmlwrs98ah8a',
      derivativeSource: 'ibc/31D711D31CD5D83D98E76B1486EEDA1A38CD1F7D6FCBD03521FE51323115AECA',
      equalToOtherIDPriceSource: 'ibc/31D711D31CD5D83D98E76B1486EEDA1A38CD1F7D6FCBD03521FE51323115AECA',
      id: 'archway1zfnzv39cp4dv3jjy0aptn5msc02tjmy602l46u90dt729q80939qjgqcdj',
      symbol: 'xGRAV',
      totalSupply: '30469487336912',
      updatedAt: '2024-03-04T04:10:07.670Z',
      price: 0.00334654,
      price_24h_change: -9.048646617619642,
      standardPoolPriceSources: [],
    },
    {
      isNative: false,
      isDerivative: true,
      isPriceWatch: false,
      isHidden: false,
      label: 'xBLD',
      address: 'archway1yv8uhe795xs4fwz6mjm278yr35ps0yagjchfp39q5x49dty9jgssm5tnkv',
      createdAt: '2023-11-30T18:20:44.247Z',
      decimals: 6,
      derivativeContract: 'archway1tysc229u2wewylvepptzq2p9ksv9lyk58wd7sxfqws377dddcflqwaqz36',
      derivativeSource: 'ibc/8CB56C813A5C2387140BBEAABCCE797AFA0960C8D07B171F71A5188726CFED2C',
      equalToOtherIDPriceSource: 'ibc/8CB56C813A5C2387140BBEAABCCE797AFA0960C8D07B171F71A5188726CFED2C',
      id: 'archway1yv8uhe795xs4fwz6mjm278yr35ps0yagjchfp39q5x49dty9jgssm5tnkv',
      symbol: 'xBLD',
      totalSupply: '1351087451432',
      updatedAt: '2024-03-04T04:10:07.731Z',
      price: 0.186593,
      price_24h_change: 1.7306836410293502,
      standardPoolPriceSources: [],
    },
    {
      isNative: true,
      isDerivative: false,
      isPriceWatch: true,
      isHidden: false,
      label: 'USDC.grav',
      createdAt: '2023-12-19T20:08:26.585Z',
      decimals: 6,
      denom: 'ibc/6F681B32B47E765AE278844A8A97C8B7AD5A3FB591E37CFDFA0E208EFEBEF97D',
      denom_trace: { path: 'transfer/channel-38', base_denom: 'gravity0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48' },
      geckoIDPriceSource: 'usd-coin',
      id: 'ibc/6F681B32B47E765AE278844A8A97C8B7AD5A3FB591E37CFDFA0E208EFEBEF97D',
      symbol: 'USDC.grav',
      updatedAt: '2024-03-04T04:10:08.379Z',
      price: 0.999544,
      price_24h_change: -0.09808128858574224,
      standardPoolPriceSources: [],
    },
    {
      isNative: true,
      isDerivative: false,
      isPriceWatch: true,
      isHidden: false,
      label: 'USDT.grav',
      createdAt: '2023-12-19T20:08:26.861Z',
      decimals: 6,
      denom: 'ibc/7F6928F9DB35D5A420330B9470EA4419B5F305EEB668D3274FC1ACDAC325ED17',
      denom_trace: { path: 'transfer/channel-38', base_denom: 'gravity0xdAC17F958D2ee523a2206206994597C13D831ec7' },
      geckoIDPriceSource: 'tether',
      id: 'ibc/7F6928F9DB35D5A420330B9470EA4419B5F305EEB668D3274FC1ACDAC325ED17',
      symbol: 'USDT.grav',
      updatedAt: '2024-03-04T04:10:08.379Z',
      price: 1.001,
      price_24h_change: 0.006802595979475077,
      standardPoolPriceSources: [],
    },
    {
      isNative: false,
      isDerivative: false,
      isPriceWatch: true,
      isHidden: false,
      label: 'bnUSD',
      address: 'archway1l3m84nf7xagkdrcced2y0g367xphnea5uqc3mww3f83eh6h38nqqxnsxz7',
      createdAt: '2023-12-27T10:43:36.490Z',
      decimals: 18,
      id: 'archway1l3m84nf7xagkdrcced2y0g367xphnea5uqc3mww3f83eh6h38nqqxnsxz7',
      staticPriceSource: 1,
      symbol: 'bnUSD',
      updatedAt: '2024-03-04T04:10:08.317Z',
      price: 1,
      totalSupply: '39004674341599919772240',
      standardPoolPriceSources: [],
    },
    {
      isNative: true,
      isDerivative: false,
      isPriceWatch: true,
      isHidden: false,
      label: 'QWOYN',
      createdAt: '2023-12-28T15:53:47.083Z',
      decimals: 6,
      denom: 'ibc/1112DFAC11501682456D28D0728C1E35E134729F4D8EAA897377ACDFB29F46CC',
      denom_trace: { path: 'transfer/channel-88', base_denom: 'uqwoyn' },
      id: 'ibc/1112DFAC11501682456D28D0728C1E35E134729F4D8EAA897377ACDFB29F46CC',
      standardPoolPriceSources: [
        {
          address: 'archway1j996zrycgw3jzjmm4nsf5qq8kwuzlwks2303ec77xkztttytflcsn6n6el',
          offerAssetDenom: 'archway1cutfh7m87cyq5qgqqw49f289qha7vhsg6wtr6rl5fvm28ulnl9ssg0vk0n',
        },
      ],
      symbol: 'QWOYN',
      updatedAt: '2024-03-04T04:10:08.379Z',
      price: 0.2842223655792828,
      xDerivativeTarget: 'archway1ndrruk2atj4yrcqsnypthvnexjv6erpwtczqlfp8d97zsv8k6wgqjmw4gt',
      price_24h_change: 4.195485649749964,
    },
    {
      isNative: false,
      isDerivative: true,
      isPriceWatch: false,
      isHidden: false,
      label: 'xQWOYN',
      address: 'archway1ndrruk2atj4yrcqsnypthvnexjv6erpwtczqlfp8d97zsv8k6wgqjmw4gt',
      createdAt: '2023-12-28T16:20:04.010Z',
      decimals: 6,
      derivativeContract: 'archway14p2dvm5ae2pkf5wes22v847ypen7hfl5jvnhgfunj8hg0sa6duwqsj7sdp',
      derivativeSource: 'ibc/1112DFAC11501682456D28D0728C1E35E134729F4D8EAA897377ACDFB29F46CC',
      equalToOtherIDPriceSource: 'ibc/1112DFAC11501682456D28D0728C1E35E134729F4D8EAA897377ACDFB29F46CC',
      id: 'archway1ndrruk2atj4yrcqsnypthvnexjv6erpwtczqlfp8d97zsv8k6wgqjmw4gt',
      symbol: 'xQWOYN',
      totalSupply: '56903822646',
      updatedAt: '2024-03-04T04:10:07.792Z',
      price: 0.2842223655792828,
      price_24h_change: 4.195485649749964,
      standardPoolPriceSources: [],
    },
    {
      isNative: true,
      isDerivative: false,
      isPriceWatch: true,
      isHidden: false,
      label: 'ANDR',
      createdAt: '2024-01-10T16:23:01.495Z',
      decimals: 6,
      denom: 'ibc/55D94A32095A766971637425D998AAABF8357A1ABCB1CAC8614887BE51BF1FB1',
      denom_trace: { path: 'transfer/channel-97', base_denom: 'uandr' },
      id: 'ibc/55D94A32095A766971637425D998AAABF8357A1ABCB1CAC8614887BE51BF1FB1',
      symbol: 'ANDR',
      updatedAt: '2024-03-04T04:10:08.379Z',
      price: 1.371000973486663,
      standardPoolPriceSources: [
        {
          address: 'archway1au4l3hpu0uhgyvkjrtmk2f6k2lkntp89z4t3m30570vpq3clgujsp2hj7v',
          offerAssetDenom: 'archway1cutfh7m87cyq5qgqqw49f289qha7vhsg6wtr6rl5fvm28ulnl9ssg0vk0n',
        },
      ],
      price_24h_change: -2.559837574998869,
    },
    {
      isNative: false,
      isDerivative: false,
      isPriceWatch: true,
      isHidden: true,
      label: 'AXV',
      address: 'archway1ecjefhcf8r60wtfnhwefrxhj9caeqa90fj58cqsaafqveawn6cjs5znd2n',
      createdAt: '2024-02-05T14:21:29.912Z',
      decimals: 6,
      id: 'archway1ecjefhcf8r60wtfnhwefrxhj9caeqa90fj58cqsaafqveawn6cjs5znd2n',
      price: 0.19032262742069067,
      symbol: 'AXV',
      totalSupply: '205357184621828',
      updatedAt: '2024-03-04T04:10:08.379Z',
      standardPoolPriceSources: [
        {
          address: 'archway1d5s2ynrfnnjckg25h57693s5c9yljesqny2f4jcgycq6d7l7hvks08hfuv',
          offerAssetDenom: 'archway1cutfh7m87cyq5qgqqw49f289qha7vhsg6wtr6rl5fvm28ulnl9ssg0vk0n',
        },
        {
          address: 'archway1m43xf7zm7p6h8tlagzm82e4tg9zlyah8x3wjha5tf7vsftyysy5qcz4c3l',
          offerAssetDenom: 'archway1yjdgfut7jkq5xwzyp6p5hs7hdkmszn34zkhun6mglu3falq3yh8sdkaj7j',
        },
        {
          address: 'archway13kjs3296p4fkzvca2grpueqprfl35er7wefzaa5n8em6u7fd5lcsvue5n3',
          offerAssetDenom: 'archway1m273xq2fjmn993jm4kft5c49w2c70yfv5zypt3d92cqp4n5faefqqkuf0l',
        },
        {
          address: 'archway1fnw37ae9rqs9alu0z9x6jxmd3tnyvnccugks3t28gg5yuv2me4rsxs7gtl',
          offerAssetDenom: 'ibc/43897B9739BD63E3A08A88191999C632E052724AB96BD4C74AE31375C991F48D',
        },
      ],
      price_24h_change: -6.572490980382428,
    },
    {
      isNative: true,
      isDerivative: false,
      isPriceWatch: true,
      isHidden: false,
      label: 'AKT',
      createdAt: '2024-02-10T12:10:34.131Z',
      decimals: 6,
      denom: 'ibc/C2CFB1C37C146CF95B0784FD518F8030FEFC76C5800105B1742FB65FFE65F873',
      denom_trace: { path: 'transfer/channel-104', base_denom: 'uakt' },
      geckoIDPriceSource: 'akash-network',
      id: 'ibc/C2CFB1C37C146CF95B0784FD518F8030FEFC76C5800105B1742FB65FFE65F873',
      symbol: 'AKT',
      updatedAt: '2024-03-04T04:10:08.379Z',
      price: 4.41,
      price_24h_change: -4.667526762983814,
      xDerivativeTarget: 'archway1tl8l2gt9dncdu6huds39dsg366ctllvtnm078qkkad2mnv28erss98tl2n',
      standardPoolPriceSources: [],
    },
    {
      isNative: false,
      isDerivative: true,
      isPriceWatch: false,
      isHidden: false,
      label: 'xAKT',
      address: 'archway1tl8l2gt9dncdu6huds39dsg366ctllvtnm078qkkad2mnv28erss98tl2n',
      createdAt: '2024-02-10T12:15:35.731Z',
      decimals: 6,
      derivativeContract: 'archway1m7yxjq4749s9ryg8r98xwvg006dkr9wmkn8682jl2rfrtehyqewsj6zuk6',
      derivativeSource: 'ibc/C2CFB1C37C146CF95B0784FD518F8030FEFC76C5800105B1742FB65FFE65F873',
      equalToOtherIDPriceSource: 'ibc/C2CFB1C37C146CF95B0784FD518F8030FEFC76C5800105B1742FB65FFE65F873',
      id: 'archway1tl8l2gt9dncdu6huds39dsg366ctllvtnm078qkkad2mnv28erss98tl2n',
      symbol: 'xAKT',
      totalSupply: '21752908550',
      updatedAt: '2024-03-04T04:10:07.852Z',
      price: 4.41,
      price_24h_change: -4.667526762983814,
      standardPoolPriceSources: [],
    },
    {
      isNative: true,
      isDerivative: false,
      isPriceWatch: true,
      isHidden: false,
      label: 'ROCK',
      createdAt: '2024-02-10T13:25:42.582Z',
      decimals: 6,
      denom: 'ibc/8F6360B49F40DA2B86F7F1A3335490E126E4DD9BAC60B5ED2EEA08D8A10DC372',
      denom_trace: { path: 'transfer/channel-98', base_denom: 'ubedrock' },
      id: 'ibc/8F6360B49F40DA2B86F7F1A3335490E126E4DD9BAC60B5ED2EEA08D8A10DC372',
      symbol: 'ROCK',
      updatedAt: '2024-03-04T04:10:08.379Z',
      price: 0.5703022769030917,
      standardPoolPriceSources: [
        {
          address: 'archway19cqd9ats5azjw4emlh5e0ucc8xn9ct37efk9pt2hlxg6f8mdacts24vlkm',
          offerAssetDenom: 'archway1cutfh7m87cyq5qgqqw49f289qha7vhsg6wtr6rl5fvm28ulnl9ssg0vk0n',
        },
      ],
    },
    {
      isNative: true,
      isDerivative: false,
      isPriceWatch: true,
      isHidden: false,
      label: 'VDL',
      createdAt: '2024-02-10T18:00:39.438Z',
      decimals: 6,
      denom: 'ibc/1BCF1FCAFE3568E234787EDFDA12460BD8931B17FE6A729DCD60FAD845558DA1',
      denom_trace: { path: 'transfer/channel-110', base_denom: 'uvdl' },
      geckoIDPriceSource: 'vidulum',
      id: 'ibc/1BCF1FCAFE3568E234787EDFDA12460BD8931B17FE6A729DCD60FAD845558DA1',
      symbol: 'VDL',
      updatedAt: '2024-03-04T04:10:08.379Z',
      price: 0.02272813,
      price_24h_change: -3.4028375978979306,
      standardPoolPriceSources: [],
    },
    {
      isNative: false,
      isDerivative: true,
      isPriceWatch: false,
      isHidden: false,
      label: 'xVDL',
      address: 'archway1wh099rqeeq8x62kv3w3m7x40p2r5sjtkk25364jwtlunzmxmmyhsh9nwl0',
      createdAt: '2024-02-10T18:00:56.512Z',
      decimals: 6,
      derivativeContract: 'archway1yr5mh0ylct8fkyftl3tgwc52yzreacds83sdl5ckwcy057gqraeqk34hmp',
      derivativeSource: 'ibc/1BCF1FCAFE3568E234787EDFDA12460BD8931B17FE6A729DCD60FAD845558DA1',
      equalToOtherIDPriceSource: 'ibc/1BCF1FCAFE3568E234787EDFDA12460BD8931B17FE6A729DCD60FAD845558DA1',
      id: 'archway1wh099rqeeq8x62kv3w3m7x40p2r5sjtkk25364jwtlunzmxmmyhsh9nwl0',
      symbol: 'xVDL',
      totalSupply: '787883110423',
      updatedAt: '2024-03-04T04:10:07.914Z',
      price: 0.02272813,
      price_24h_change: -3.4028375978979306,
      standardPoolPriceSources: [],
    },
    {
      isNative: true,
      isDerivative: false,
      isPriceWatch: true,
      isHidden: false,
      label: 'MPWR',
      createdAt: '2024-02-16T17:04:11.834Z',
      decimals: 6,
      denom: 'ibc/28A2923B26BD4CED9D664B032904D37AABE1F08E8C9E97B0FA18E885CA978EBC',
      denom_trace: { path: 'transfer/channel-111', base_denom: 'umpwr' },
      id: 'ibc/28A2923B26BD4CED9D664B032904D37AABE1F08E8C9E97B0FA18E885CA978EBC',
      symbol: 'MPWR',
      updatedAt: '2024-03-04T04:10:08.379Z',
      price: 0.020711136892578686,
      xDerivativeTarget: 'archway1tvrrctwllg8aalc4ruk6a4zxtel8ff7ggxljvu6ffj3wpm2zp8kqyecxpr',
      standardPoolPriceSources: [
        {
          address: 'archway19afhcz9s5cr98um84qjz0vu6khqpduurhep9u3ds3lytp7c5m2mstgs23d',
          offerAssetDenom: 'archway1cutfh7m87cyq5qgqqw49f289qha7vhsg6wtr6rl5fvm28ulnl9ssg0vk0n',
        },
      ],
    },
    {
      isNative: false,
      isDerivative: true,
      isPriceWatch: false,
      isHidden: false,
      label: 'xMPWR',
      address: 'archway1tvrrctwllg8aalc4ruk6a4zxtel8ff7ggxljvu6ffj3wpm2zp8kqyecxpr',
      createdAt: '2024-02-16T17:06:36.851Z',
      decimals: 6,
      derivativeContract: 'archway1zyqezd505kdw9nte8nc4a2fej6f86xrc58ma2u7zca7p68vkagnqssy4f5',
      derivativeSource: 'ibc/28A2923B26BD4CED9D664B032904D37AABE1F08E8C9E97B0FA18E885CA978EBC',
      equalToOtherIDPriceSource: 'ibc/28A2923B26BD4CED9D664B032904D37AABE1F08E8C9E97B0FA18E885CA978EBC',
      id: 'archway1tvrrctwllg8aalc4ruk6a4zxtel8ff7ggxljvu6ffj3wpm2zp8kqyecxpr',
      symbol: 'xMPWR',
      totalSupply: '1896057371438',
      updatedAt: '2024-03-04T04:10:08.039Z',
      price: 0.020711136892578686,
      price_24h_change: null,
      standardPoolPriceSources: [],
    },
    {
      isNative: true,
      isDerivative: false,
      isPriceWatch: true,
      isHidden: false,
      label: 'LVN',
      createdAt: '2024-02-20T16:18:52.606Z',
      decimals: 6,
      denom: 'ibc/6A9571DE6A3F60D7703C3290E2944E806C15A47C1EA6D4AFCD3AE4DC8AF080B1',
      denom_trace: {
        path: 'transfer/channel-1',
        base_denom: 'factory/osmo1mlng7pz4pnyxtpq0akfwall37czyk9lukaucsrn30ameplhhshtqdvfm5c/ulvn',
      },
      geckoIDPriceSource: 'levana-protocol',
      id: 'ibc/6A9571DE6A3F60D7703C3290E2944E806C15A47C1EA6D4AFCD3AE4DC8AF080B1',
      symbol: 'LVN',
      updatedAt: '2024-03-04T04:10:08.379Z',
      price: 0.161523,
      price_24h_change: -0.9732861594239712,
      standardPoolPriceSources: [],
    },
    {
      isNative: true,
      isDerivative: false,
      isPriceWatch: true,
      isHidden: false,
      label: 'PLQ',
      createdAt: '2024-02-23T21:10:34.828Z',
      decimals: 18,
      denom: 'ibc/CFD58F8A64F93940D00CABE85B05A6D0FBA1FF4DF42D3C1E23C06DF30A2BAE1F',
      denom_trace: { path: 'transfer/channel-112', base_denom: 'aplanq' },
      geckoIDPriceSource: 'planq',
      id: 'ibc/CFD58F8A64F93940D00CABE85B05A6D0FBA1FF4DF42D3C1E23C06DF30A2BAE1F',
      symbol: 'PLQ',
      updatedAt: '2024-03-04T04:10:08.379Z',
      price: 0.068875,
      price_24h_change: -0.4386370967217913,
      xDerivativeTarget: 'archway1h7vfp6hjjluw8n6m2v4tkfdw3getkwqldu59xghltdskt3rh6shqczumjc',
      standardPoolPriceSources: [],
    },
    {
      isNative: false,
      isDerivative: true,
      isPriceWatch: false,
      isHidden: false,
      label: 'xPLQ',
      address: 'archway1h7vfp6hjjluw8n6m2v4tkfdw3getkwqldu59xghltdskt3rh6shqczumjc',
      createdAt: '2024-02-23T21:27:04.532Z',
      decimals: 18,
      derivativeContract: 'archway1jztl7elm4v0srlv043jaa28ctz9p85rlquknlkr2j6fewndfygms3607vj',
      derivativeSource: 'ibc/CFD58F8A64F93940D00CABE85B05A6D0FBA1FF4DF42D3C1E23C06DF30A2BAE1F',
      equalToOtherIDPriceSource: 'ibc/CFD58F8A64F93940D00CABE85B05A6D0FBA1FF4DF42D3C1E23C06DF30A2BAE1F',
      id: 'archway1h7vfp6hjjluw8n6m2v4tkfdw3getkwqldu59xghltdskt3rh6shqczumjc',
      symbol: 'xPLQ',
      totalSupply: '1700001982933000000000000',
      updatedAt: '2024-03-04T04:10:08.101Z',
      price: 0.068875,
      price_24h_change: -0.4386370967217913,
      standardPoolPriceSources: [],
    },
  ],
};

export default denoms;
