import { useWallet } from '@hooks/useWallet';
import { useChainId } from '@hooks/useChainId';
import { useChainClient } from '@hooks/useChainClient';
import { useQuery } from '@tanstack/react-query';

const useBalances = (injectedAddress: string | null = null) => {
  const { address: walletAddress } = useWallet();
  const { chainId } = useChainId();
  const client = useChainClient(chainId);

  const address = injectedAddress ?? walletAddress;

  const { data: balances, ...helpers } = useQuery(
    ['balances', chainId, address],
    () => client!.fetchBalances(address!),
    {
      enabled: !!address && !!client,
      meta: {
        errorMessage: `Error fetching balances for ${address}`,
      },
    },
  );

  return {
    balances,
    ...helpers,
  };
};

export default useBalances;
