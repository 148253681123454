export enum Pages {
  Home = '/',
  Strategies = '/strategies',
  CreateStrategy = '/create-strategy',
  GetAssets = '/bridge-assets',
  HowItWorks = '/how-it-works',
  LearnAboutCalc = '/learn-about-calc',
  StatsAndTotals = '/stats-and-totals',
  AllStrategies = '/admin/strategies',
  ProStrategies = '/pro-strategies',
}
