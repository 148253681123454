const denoms = {
  ok: true,
  data: [
    {
      isNative: false,
      isDerivative: false,
      isPriceWatch: true,
      isHidden: true,
      label: 'Astrovault',
      address: 'archway1qrvfsfdyw79g8zxepkafrsj53xvz2v072mk6kqe3yj82n8ahznpssuzzc5',
      createdAt: '2023-05-20T16:01:44.763Z',
      decimals: 6,
      id: 'archway1qrvfsfdyw79g8zxepkafrsj53xvz2v072mk6kqe3yj82n8ahznpssuzzc5',
      standardPoolPriceSources: [
        {
          address: 'archway1wa6wqxg925a6xfpltvsv47dyzw6xqz2kvxmfylj7p4cdp8736zqqjl9kzp',
          offerAssetDenom: 'archway1sdzaas0068n42xk8ndm6959gpu6n09tajmeuq7vak8t9qt5jrp6sjjtnka',
        },
      ],
      symbol: 'AXV',
      totalSupply: '99999990000018675813369952',
      updatedAt: '2024-03-04T00:50:02.138Z',
      price: 0.027923530797158615,
      price_24h_change: 0,
    },
    {
      isNative: true,
      isDerivative: false,
      isPriceWatch: true,
      isHidden: false,
      id: 'aconst',
      createdAt: '2023-05-20T16:06:00.725Z',
      decimals: 18,
      denom: 'aconst',
      xDerivativeTarget: 'archway1sdzaas0068n42xk8ndm6959gpu6n09tajmeuq7vak8t9qt5jrp6sjjtnka',
      isSourceDenom: true,
      label: 'Constantine(native)',
      standardPoolPriceSources: [
        {
          address: 'archway1jdrvvzd2tcfvhvyaedy7e8s92lh2m3a3jklvn74768fh6n5quh4sl6rgkx',
          offerAssetDenom: 'archway1mmu32f7hn0fyw8gh57xl5uhaqu4pq5xx59ynf0tju60n2nzha0as3vtmcm',
        },
      ],
      symbol: 'CONST',
      updatedAt: '2024-03-04T00:50:02.139Z',
      price: 0.6703490349446172,
      price_24h_change: 0,
    },
    {
      isNative: false,
      isDerivative: true,
      isPriceWatch: false,
      isHidden: false,
      label: 'xCONST',
      address: 'archway1sdzaas0068n42xk8ndm6959gpu6n09tajmeuq7vak8t9qt5jrp6sjjtnka',
      createdAt: '2023-05-20T16:06:06.177Z',
      decimals: 18,
      derivativeContract: 'archway1e2htaf03fu9y9ez4hc8uky3rzvnagh7wnj8sn2e7hgsjqwwc4x6s55twtp',
      derivativeSource: 'aconst',
      equalToOtherIDPriceSource: 'aconst',
      id: 'archway1sdzaas0068n42xk8ndm6959gpu6n09tajmeuq7vak8t9qt5jrp6sjjtnka',
      symbol: 'xCONST',
      totalSupply: '21935522611140242997859',
      updatedAt: '2024-03-04T00:50:01.794Z',
      price: 0.6703490349446172,
      price_24h_change: 0,
      standardPoolPriceSources: [],
    },
    {
      isNative: true,
      isDerivative: false,
      isPriceWatch: true,
      isHidden: false,
      label: 'Axelar(TESTNET)',
      createdAt: '2023-05-20T16:29:04.301Z',
      decimals: 6,
      denom: 'ibc/A1F5AED38B17FC8C2009DD6BD70E463C7D3641B35D1676680CF9329D85C28018',
      denom_trace: { path: 'transfer/channel-50', base_denom: 'uaxl' },
      geckoIDPriceSource: 'axelar',
      id: 'ibc/A1F5AED38B17FC8C2009DD6BD70E463C7D3641B35D1676680CF9329D85C28018',
      staticPriceSource: null,
      symbol: 'AXL',
      updatedAt: '2024-03-04T00:50:02.138Z',
      price: 2.42,
      price_24h_change: 23.025739238654033,
      disabled: false,
      standardPoolPriceSources: [],
    },
    {
      isNative: false,
      isDerivative: false,
      isPriceWatch: false,
      isHidden: false,
      label: 'USDC.axv',
      address: 'archway1mmu32f7hn0fyw8gh57xl5uhaqu4pq5xx59ynf0tju60n2nzha0as3vtmcm',
      createdAt: '2023-05-20T18:00:30.035Z',
      decimals: 18,
      id: 'archway1mmu32f7hn0fyw8gh57xl5uhaqu4pq5xx59ynf0tju60n2nzha0as3vtmcm',
      staticPriceSource: 1,
      symbol: 'USDC.axv',
      totalSupply: '1000000000000000000000000000000000',
      updatedAt: '2024-03-04T00:50:01.856Z',
      price: 1,
      standardPoolPriceSources: [],
    },
    {
      isNative: false,
      isDerivative: false,
      isPriceWatch: false,
      isHidden: false,
      label: 'BUSD.axv',
      address: 'archway1lcxaem4gqnp2mdeh5hawacxlzge8e6wzjaat73ahpf2rclqv9v4sj8e4kf',
      createdAt: '2023-05-20T18:00:39.959Z',
      decimals: 12,
      id: 'archway1lcxaem4gqnp2mdeh5hawacxlzge8e6wzjaat73ahpf2rclqv9v4sj8e4kf',
      staticPriceSource: 1,
      symbol: 'BUSD.axv',
      totalSupply: '1000000000000000000000000000000000',
      updatedAt: '2024-03-04T00:50:01.919Z',
      price: 1,
      standardPoolPriceSources: [],
    },
    {
      isNative: false,
      isDerivative: false,
      isPriceWatch: false,
      isHidden: false,
      id: 'archway12defza8nz2d2a3hgt6tftku28ly5lglsna69j7rpjeukg4pz8qese2326s',
      label: 'USDT.axv',
      address: 'archway12defza8nz2d2a3hgt6tftku28ly5lglsna69j7rpjeukg4pz8qese2326s',
      symbol: 'USDT.axv',
      decimals: 12,
      staticPriceSource: 1,
      totalSupply: '1000000000000000000000000000000000',
      price: 1,
      updatedAt: '2024-03-04T00:50:01.983Z',
      standardPoolPriceSources: [],
    },
    {
      isNative: false,
      isDerivative: false,
      isPriceWatch: true,
      isHidden: false,
      label: 'BTC.axv',
      address: 'archway128zqyqk70d4xs74n3c248ramaymm8s9p4thcpfrfykd07758fsmqu67jf6',
      createdAt: '2023-07-26T15:17:39.020Z',
      decimals: 18,
      geckoIDPriceSource: 'bitcoin',
      id: 'archway128zqyqk70d4xs74n3c248ramaymm8s9p4thcpfrfykd07758fsmqu67jf6',
      symbol: 'BTC.axv',
      totalSupply: '1000000000000000000000000000000000',
      updatedAt: '2024-03-04T00:50:02.138Z',
      price: 64190,
      price_24h_change: 3.8287404528083604,
      standardPoolPriceSources: [],
    },
    {
      isNative: false,
      isDerivative: false,
      isPriceWatch: true,
      isHidden: false,
      label: 'ETH.axv',
      address: 'archway1amxcch6d85t9062rps0x2qr8q799ucdfnthjy42zkndgegpdp89qlczwer',
      createdAt: '2023-07-26T15:18:31.183Z',
      decimals: 18,
      geckoIDPriceSource: 'ethereum',
      id: 'archway1amxcch6d85t9062rps0x2qr8q799ucdfnthjy42zkndgegpdp89qlczwer',
      symbol: 'ETH.axv',
      totalSupply: '1000000000000000000000000000000000',
      updatedAt: '2024-03-04T00:50:02.138Z',
      price: 3530.08,
      price_24h_change: 3.0787947458340987,
      standardPoolPriceSources: [],
    },
    {
      isNative: false,
      isDerivative: false,
      isPriceWatch: true,
      isHidden: false,
      label: 'sARCH',
      address: 'archway1erqguqc3hmfajgu7e2dvgaccx6feu5ru3gyatdxu94p66j9hp7msn2kcqp',
      createdAt: '2023-12-19T22:49:55.418Z',
      decimals: 18,
      id: 'archway1erqguqc3hmfajgu7e2dvgaccx6feu5ru3gyatdxu94p66j9hp7msn2kcqp',
      price: 0.185,
      symbol: 'sARCH',
      updatedAt: '2024-03-04T00:43:29.208Z',
      totalSupply: '12739115527549251783049',
      standardPoolPriceSources: [],
    },
    {
      isNative: false,
      isDerivative: false,
      isPriceWatch: true,
      isHidden: true,
      label: 'ATOM.axv',
      address: 'archway107sv2nzumf2f8wy794z7cc4w2mul2m2ls28j7h9e3z4uxgsszavs7vlpcn',
      createdAt: '2024-01-08T10:37:31.835Z',
      decimals: 6,
      id: 'archway107sv2nzumf2f8wy794z7cc4w2mul2m2ls28j7h9e3z4uxgsszavs7vlpcn',
      price: 10.5,
      staticPriceSource: 10.5,
      symbol: 'ATOM.axv',
      totalSupply: '1000000000000000',
      updatedAt: '2024-03-04T00:50:02.061Z',
      standardPoolPriceSources: [],
    },
  ],
};

export default denoms;
